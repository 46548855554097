/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    h2: "h2"
  }, _provideComponents(), props.components), {Box} = _components;
  if (!Box) _missingMdxReference("Box", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Ennen kuin puhutaan strategiakartoista, pitää kerrata mikä on tasapainoitettu tuloskortti (Balanced Scorecard, BSC). Tasapainoitettu tuloskortti on suorituskyvymittaristo, joka on nimensä mukaisesti kehitetty tasapainottamaan yritysten usein hyvin talouslukulähtöisiä suorituskyvyn mittareita. Talouden lukujen lisäksi - tai pikemminkin niitä ennen - mitataan oppimista ja kasvua, sisäisiä prosesseja ja asiakasarvoa. Mittaristossa järjestyksellä ei sinänsä ole merkitystä, mutta strategisia avainlukuja tai itse strategiaa suunniteltaessa syy-seuraussuhteet astuvat kuvaan. Ja niitä kuvataan strategiakartalla."), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[0]),
    alt: "Tasapainotettu tuloskortti, eli Balanced Scorecard"
  })), "\n", React.createElement(_components.p, null, "Strategiakartta on yksinkertaistetusti työkalu, jolla kuvataan BSC:n neljälle osa-alueelle sijoitettujen toimenpiteiden tai tavoitteiden kausaalista suhdetta. Kun kehitetään nopeampi toimitusprosessi, asiakastyytyväisyys kasvaa, joka johtaa pysyvämpiin asiakassuhteisiin ja lisätilauksiin, ja sitä kautta liikevaihdon kasvuun. Pahoitteluni kliseisimmästä mahdollisesta esimerkistä :)"), "\n", React.createElement(_components.p, null, "Mitattavista asioista syntyy joko ennakoivia mittareita tai tulosmittareita (leading indicators, lagging indicators)."), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Strategiakartalla"), " kuvataan näitä ennakoivia toimenpiteitä ja mittareita, sekä niistä odotettuja tuloksia."), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[1]),
    alt: "Esimerkki strategiakartasta"
  })), "\n", React.createElement(_components.h2, null, "Strategiakartta rakenteiden mallintajana ja tasapainottajana"), "\n", React.createElement(_components.p, null, "Koska strategiakartta on osa Balanced Scorecard -mallia, se otetaan työkalupakista usein kun suunnitellaan toimenpiteitä ja suorituskyvyn mittareita. Se on kuitenkin mainio työkalu fasilitoimaan keskustelua yrityksen toimintojen ja prosessien rakenteista. Talousluvuista ja asiakastyytyväisyydestä puhutaan yrityksissä usein, mutta vähemmän niitä konkreettisesti tuottavista sisäisistä prosesseista ja ", React.createElement(_components.strong, null, "aivan liian vähän"), " yrityksen tietopääomasta, inhimillisestä pääomasta ja kaiken taustalla olevasta organisaatiopääomasta."), "\n", React.createElement(_components.p, null, "Strategiakartta voi myös avata näkökulmia informaatiopääoman ja sisäisten prosessien rajapintaan, jos yritykseen halutaan tuoda Lean-ajattelua tai muuten tunnistaa pullonkauloja ja prosessin kehittämistarpeita."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
