import GATSBY_COMPILED_MDX from "/app/src/articles/2022-09-strategiakartta.mdx";
import React from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {graphql} from 'gatsby';
import {Link} from 'gatsby-theme-material-ui';
import {Typography, Box, Breadcrumbs, IconButton, Tooltip} from '@mui/material';
import {css} from '@emotion/react';
import {MDXProvider} from '@mdx-js/react';
import {IconBrandLinkedin, IconBrandTwitter, IconClipboardCopy, IconBrandFacebook} from '@tabler/icons';
import dayjs from '../utils/dayjs';
import MainLayout from '../components/MainLayout';
import Block from '../components/Block';
import SmallHero from '../components/SmallHero';
import ArticleTag from '../components/ArticleTag';
import backgroundImage from '../images/tech/fabio-oyXis2kALVg-unsplash.jpg';
export const query = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      excerpt(pruneLength: 250)
      frontmatter {
        title
        date
        slug
        tags
        excerpt
        images {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
const shortcodes = {
  Link,
  Typography,
  Box,
  GatsbyImage,
  getImage,
  css
};
function getUrl(data) {
  const host = process.env.GATSBY_HOST_URL;
  return `${host}${data.mdx.frontmatter.slug}`;
}
export function Head({data}) {
  const excerpt = data.mdx.frontmatter.excerpt || data.mdx.excerpt;
  const {title} = data.mdx.frontmatter;
  return React.createElement(React.Fragment, null, React.createElement("title", null, data.mdx.frontmatter.title, ' ', " - Veikko Mäkinen"), React.createElement("meta", {
    property: "og:title",
    content: title
  }), React.createElement("meta", {
    property: "og:description",
    content: excerpt
  }), React.createElement("meta", {
    property: "og:url",
    content: getUrl(data)
  }));
}
function PageTemplate({data, children}) {
  const date = dayjs(data.mdx.frontmatter.date).format('YYYY / MMMM');
  const Tags = data.mdx.frontmatter.tags?.map(tag => React.createElement(ArticleTag, {
    key: tag,
    label: tag,
    variant: "outlined",
    size: "small"
  }));
  return React.createElement(MainLayout, null, React.createElement(SmallHero, {
    title: data.mdx.frontmatter.title,
    backgroundImage: backgroundImage,
    overlayColor: "#004",
    overlayOpacity: 0.4
  }), React.createElement(Box, {
    sx: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: 1
    },
    role: "presentation"
  }, React.createElement(Breadcrumbs, {
    "aria-label": "breadcrumb",
    separator: "›"
  }, React.createElement(Link, {
    underline: "hover",
    color: "inherit",
    to: "/ideat"
  }, " Ideat"), React.createElement(Link, {
    underline: "hover",
    color: "text.primary",
    to: data.mdx.frontmatter.slug,
    "aria-current": "page"
  }, data.mdx.frontmatter.title))), React.createElement(Block, null, React.createElement(Box, {
    sx: {
      mb: 1
    }
  }, Tags), React.createElement(Typography, {
    variant: "subtitle2",
    gutterBottom: true
  }, date), React.createElement(Typography, {
    variant: "h2"
  }, data.mdx.frontmatter.title), React.createElement(MDXProvider, {
    components: shortcodes,
    foo: "123"
  }, children)), React.createElement(Box, {
    sx: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  }, React.createElement(Box, null, React.createElement(Tooltip, {
    title: "Jaa LinkedInissä",
    placement: "top",
    arrow: true
  }, React.createElement(IconButton, {
    href: `https://linkedin.com/shareArticle?url=${getUrl(data)}`,
    target: "_blank",
    color: "secondary",
    "aria-label": "LinkedIn share"
  }, React.createElement(IconBrandLinkedin, {
    width: 24,
    height: 24
  }))), React.createElement(Tooltip, {
    title: "Jaa Twitterissä",
    placement: "top",
    arrow: true
  }, React.createElement(IconButton, {
    href: `http://twitter.com/share?url=${getUrl(data)}`,
    target: "_blank",
    color: "secondary",
    "aria-label": "Twitter share"
  }, React.createElement(IconBrandTwitter, {
    width: 24,
    height: 24
  }))), React.createElement(Tooltip, {
    title: "Jaa Facebookissa",
    placement: "top",
    arrow: true
  }, React.createElement(IconButton, {
    href: `http://www.facebook.com/sharer.php?u=${getUrl(data)}`,
    target: "_blank",
    color: "secondary",
    "aria-label": "Facebook share"
  }, React.createElement(IconBrandFacebook, {
    width: 24,
    height: 24
  }))), React.createElement(Tooltip, {
    title: "Kopioi linkki leikepöydälle",
    placement: "top",
    arrow: true
  }, React.createElement(IconButton, {
    color: "secondary",
    "aria-label": "Copy to clipboard",
    onClick: () => {
      navigator.clipboard.writeText(getUrl(data));
    }
  }, React.createElement(IconClipboardCopy, {
    width: 24,
    height: 24
  }))))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};
